import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import QuoteOpen from "../components/img/icons/quoteOpen"
import QuoteClose from "../components/img/icons/quoteClose"

const Quotes = ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        cptQuotes {
          nodes {
            title
            id
            acf_quotes {
              jobTitle
              jobSubtitle
              quote
            }
          }
        }
      }
    }
  `)
  const quote = data.wpgraphql.cptQuotes
  return (
    <Row className="bg-light section__quote">
      <Col md={{ offset: 1, size: 10 }} className="py-3 px-2 px-md-0 py-md-5">
        {quote.nodes.map((quot, i) => (
          <div key={i}>
            {quot.id === id && (
              <Container className="d-flex bg-white section__quote_card">
                <Row className="">
                  <Col md={{ size: 2 }} className="p-0 quote-left">
                    <QuoteOpen />
                  </Col>
                  <Col md={{ size: 8 }} className="py-1 text-center">
                    <h2>{quot.title}</h2>
                    <p>
                      {" "}
                      {quot.acf_quotes.jobTitle != null && (
                        <span>
                          <b>{quot.acf_quotes.jobTitle}</b> &#47;
                        </span>
                      )}{" "}
                      {quot.acf_quotes.jobSubtitle}
                    </p>
                    <p>
                      <i>{quot.acf_quotes.quote}</i>
                    </p>
                  </Col>
                  <Col md={{ size: 2 }} className="p-0 text-right quote-right">
                    <QuoteClose />
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        ))}
      </Col>
    </Row>
  )
}

export default Quotes
