export default [
  {
    word: "Community Building",
    icon: "communityblg",
  },
  {
    word: "Environmental Wellness",
    icon: "environmental",
  },
  {
    word: "Economic Success",
    icon: "econsuccess",
  },
]
