import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react"

class GoogleMap extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
  }
  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true,
    })
  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    })
  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false,
      })
  }

  render() {
    if (!this.props.loaded) return <div>Loading...</div>
    var divStyle = {
      zIndex: "100000",
    }
    return (
      <>
        <ul className="gmap__legend" style={divStyle}>
          <li className="gmap__legend-item primary">
            <h4 className="heading4">New Construction</h4>
          </li>
          <li className="gmap__legend-item gray">
            <h4 className="heading4">Rehabilitation</h4>
          </li>
          <li className="gmap__legend-item secondary">
            <h4 className="heading4">Acquisition</h4>
          </li>
        </ul>
        <StaticQuery
          query={graphql`
            query {
              wpgraphql {
                cptMapmarkers(first: 75, after: null) {
                  nodes {
                    title
                    acf_mapmarkers {
                      markerGroup {
                        latitude
                        longitude
                        status
                        showOnMap
                        street
                        city
                        state
                        portfolioStatus
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <Map
              className="map"
              google={this.props.google}
              initialCenter={{
                lat: 37.3539673,
                lng: -121.8240695,
                //lat: 36.737797,
                //lng: -119.787125
              }}
              //disableDefaultUI={true}
              mapTypeControl={false}
              streetViewControl={false}
              rotateControl={false}
              fullscreenControl={false}
              onClick={this.onMapClicked}
              style={{ height: "100%", position: "relative", width: "100%" }}
              zoom={6}
              gestureHandling={"cooperative"}
              styles={[
                {
                  featureType: "administrative",
                  elementType: "labels.text.fill",
                  stylers: [
                    { color: "#00426a" },
                    { saturation: "-75" },
                    { lightness: "30" },
                  ],
                },
                {
                  featureType: "landscape",
                  elementType: "labels.text.fill",
                  stylers: [
                    { color: "#00426a" },
                    { saturation: "-27" },
                    { lightness: "22" },
                  ],
                },
                {
                  featureType: "landscape.man_made",
                  elementType: "all",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "landscape.natural",
                  elementType: "geometry.fill",
                  stylers: [
                    { color: "#0076a8" },
                    { lightness: "84" },
                    { saturation: "-80" },
                    { gamma: "1.00" },
                  ],
                },
                {
                  featureType: "poi",
                  elementType: "all",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "road",
                  elementType: "geometry.stroke",
                  stylers: [{ visibility: "off" }],
                },
                {
                  featureType: "road",
                  elementType: "labels",
                  stylers: [{ weight: "0.01" }],
                },
                {
                  featureType: "road",
                  elementType: "labels.text.fill",
                  stylers: [
                    { color: "#00426a" },
                    { lightness: "47" },
                    { saturation: "-75" },
                  ],
                },
                {
                  featureType: "road",
                  elementType: "labels.icon",
                  stylers: [
                    { weight: "0.01" },
                    { lightness: "36" },
                    { saturation: "-59" },
                  ],
                },
                {
                  featureType: "road.highway",
                  elementType: "geometry.fill",
                  stylers: [
                    { color: "#0076a8" },
                    { weight: "1.00" },
                    { lightness: "45" },
                    { saturation: "-67" },
                  ],
                },
                {
                  featureType: "road.highway",
                  elementType: "labels.icon",
                  stylers: [
                    { saturation: "-62" },
                    { visibility: "simplified" },
                    { gamma: "0.66" },
                    { hue: "#0075ff" },
                    { lightness: "17" },
                  ],
                },
                {
                  featureType: "road.arterial",
                  elementType: "geometry.fill",
                  stylers: [
                    { color: "#0076a8" },
                    { lightness: "63" },
                    { saturation: "-76" },
                    { weight: "2" },
                  ],
                },
                {
                  featureType: "road.local",
                  elementType: "all",
                  stylers: [
                    { visibility: "simplified" },
                    { color: "#0076a8" },
                    { lightness: "66" },
                    { saturation: "-81" },
                  ],
                },
                {
                  featureType: "transit.station",
                  elementType: "geometry.fill",
                  stylers: [
                    { color: "#0076a8" },
                    { lightness: "70" },
                    { saturation: "-78" },
                  ],
                },
                {
                  featureType: "transit.station",
                  elementType: "geometry.stroke",
                  stylers: [{ lightness: "50" }],
                },
                {
                  featureType: "transit.station",
                  elementType: "labels.text",
                  stylers: [{ saturation: "-47" }],
                },
                {
                  featureType: "water",
                  elementType: "all",
                  stylers: [
                    { color: "#0076a8" },
                    { visibility: "on" },
                    { lightness: "58" },
                    { saturation: "-44" },
                    { gamma: "0.87" },
                  ],
                },
              ]}
            >
              {data.wpgraphql.cptMapmarkers.nodes.map(nodes => {
                const markerPath = `/images/markers/${nodes.acf_mapmarkers.markerGroup.status}.png`

                return (
                  nodes.acf_mapmarkers.markerGroup.showOnMap === true && (
                    <Marker
                      name={nodes.title}
                      description={`${nodes.acf_mapmarkers.markerGroup.street}, ${nodes.acf_mapmarkers.markerGroup.city}, ${nodes.acf_mapmarkers.markerGroup.state}`}
                      portstatus={
                        nodes.acf_mapmarkers.markerGroup.portfolioStatus
                      }
                      onClick={this.onMarkerClick}
                      position={{
                        lat: nodes.acf_mapmarkers.markerGroup.latitude,
                        lng: nodes.acf_mapmarkers.markerGroup.longitude,
                      }}
                      icon={{
                        url: markerPath,
                        anchor: new this.props.google.maps.Point(32, 32),
                        scaledSize: new this.props.google.maps.Size(32, 32),
                      }}
                    />
                  )
                )
              })}
              <InfoWindow
                marker={this.state.activeMarker}
                onClose={this.onInfoWindowClose}
                visible={this.state.showingInfoWindow}
              >
                <div class="gmap__tooltip-text">
                  <h2 className="">{this.state.selectedPlace.name}</h2>
                  <p className="gmap__tooltip-para">
                    {this.state.selectedPlace.description}
                    {this.state.selectedPlace.portstatus != null && (
                      <span>{this.state.selectedPlace.portstatus}</span>
                    )}
                  </p>
                </div>
              </InfoWindow>
            </Map>
          )}
        />
      </>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBlkWAJg-w5n2jMZjDWYXyeBP1aN1_zGJQ",
})(GoogleMap)
